import './chatgpt.css';
// import './welcome.css';
import React, { useState, useEffect } from 'react';
import api from './api'
import Spinner from './components/spinner';
import ChatMessages from './components/chatMessages';
import WelcomeScreen from './components/welcomeScreen';


const App = () => {
  const [messages, setMessages] = useState([]); //initiate messages with empty list
  const [chatData, setChatData] = useState({
    message_text: '',
    thread_id: ''
  }); //used to read chatdata, and reset chatbox
  let [loading, setLoading] = useState(false); //used to show loading spinner
  const [threadId, setThreadId] = useState(''); //used to keep track of the threadId

  const fetchAnswer = async () => {
    const response = await api.get(`assistant/thread/${threadId}`);
    setMessages(response.data);
  };

  const createUserMessage = async () => {
  
    const response = await api.get(`assistant/messages/${threadId}`)
    setMessages(response.data);
  };

  const fetchThreadId = async () => {
    const response = await api.get('/assistant/newthread');
    setThreadId(response.data);
  };


  //utilizes the api to fetch the messages
  useEffect(() => {
    fetchThreadId();
  }, []);


  const handleInputChange = (event) => {
    setChatData({...chatData,
        [event.target.name] : event.target.value,
        "thread_id" : threadId });

  };


  const handleFormSubmit = async (event) => {
    event.preventDefault(); //prevents the submit, runs our function instead. 
    setChatData({message_text : ""});
    setLoading(true);
    
    const msg =  await api.post('/assistant', chatData);
    //create user message
    await createUserMessage();
    
    //fetch the answer
    await fetchAnswer();

    // fetchMessages();
    setLoading(false);

  }


  return (
    <div className="chat-container">
      {messages.length === 0 ? <WelcomeScreen/> : null}
      <ChatMessages messages={messages}/>
      {loading ? <Spinner/> : null}

      <form onSubmit={handleFormSubmit} className="message-form" id ="textForm">
          <textarea
            placeholder="Skriv hvilke madvarer du har i køleskabet, og jeg vil sammensætte en lækker opskrift ud fra dine rester"
            name="message_text"
            value ={chatData.message_text}
            onChange={handleInputChange}
            className="message-input"
          required/>
          <button type="submit" className="send-button">Send</button>
      </form>
    </div>

  )


}




export default App;


         