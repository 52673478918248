import React, { useState, useEffect } from 'react';

const TypewriterEffect = ({ text, speed = 20 }) => {
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDisplayText((currentText) => {
        if (currentText.length < text.length) {
          return text.substring(0, currentText.length + 1);
        } else {
          clearInterval(intervalId);
          return currentText;
        }
      });
    }, speed);

    return () => clearInterval(intervalId);
  }, [text, speed]);

  return <p className='message-text'>{displayText}</p>;
};



export default function ChatMessages({ messages }) { 

    return (
        <div className="chat-messages" id="chat-field">
            {messages.map((message, index) => (
                <div key={index} className={`message ${message.role}-box`}>
                    <div className={`message-meta ${message.role}-meta`}>
                        <img src={`${message.role}-icon.png`} className="author-img" alt="icon to display which user said what in the bubble"></img>
                        <span className="message-author">{message.role}</span>
                    </div>
                    <div className={`message-content ${message.role}-content`}>
                        {index === 0 && message.role === 'assistant' ? <TypewriterEffect text={message.content[0].text.value} /> :  <p className="message-text">{message.content[0].text.value}</p>}
                    </div>
                </div>
            ))}
            <div id="scroll-to"></div>
        </div>
    );
}
