import React from 'react';



export default function WelcomeScreen() { 

    return (
    
        <div className='welcome'>
            <div className = "welcome-header">
                <img src="droplet.png" alt="welcome saying and a picture of a drop"/>
            </div>
        </div>
    );
}
